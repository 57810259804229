.table {
  width: 100%;
  margin-bottom: $spacer;
  color: $table-color;
  background-color: $table-bg; // Reset for nesting within parents with `background-color`.

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: middle;
    border-top: $table-border-width solid $table-border-color;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: (2 * $table-border-width) solid $table-border-color;
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }
}

.hidden_content {
  visibility: hidden;
}

.hidden_handler:hover .hidden_content {
  visibility: visible;
}

.overlay-confirmAlert {
  z-index: 2050 !important;
}

.xl-confirmAlert {
  z-index: 2050 !important;
}
.xl-confirmAlert h1 {
  font-size: 26px;
}

.xl-confirmAlert .react-confirm-alert-body {
  position: relative;
  width: 1024px;
  max-height: 800px;
  font-size: 12px;
  overflow: auto;
}

.xl-confirmAlert .react-confirm-alert-body .react-confirm-alert-button-group {
  position: absolute;
  top: 0;
  right: 0;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.sync_status_tooltip {
  max-width: 250px;
  background-color: #000000d6;
  border-radius: 7px;
}

.nav-tabs .nav-link {
  cursor: pointer;
  user-select: none;
}

// #mnt_category .react-autosuggest__suggestions-container react-autosuggest__suggestions-container--open {
.mnt_category .react-autosuggest__suggestions-container {
  min-width: 300px;
}