.nav-tabs,
.nav-pills {
  .nav-link {
    color: $body-color;
    &:hover {
      color: $primary;
    }
  }
}

.tab-content {
  background: $white;
  &.tabcontent-border {
    border: 1px solid $border-color;
    border-top: 0px;
  }
}
// this is for custom pills
.nav-pills.custom-pills {
    .nav-link {
        border-radius: 0;
        opacity: 0.7;
        &.active {
            color: $themecolor;
            opacity: 1;
            background-color: transparent;
            border-bottom: 2px solid $themecolor;
        }
    }
}


.nav-tabs-bottom {
  border-top: $nav-tabs-border-width solid $gray-100;
  border-bottom: 0 !important;
  .nav-link {
    border: 0;
    border-top: 3px solid $white;
  }
  .nav-link:hover {
    border-top-color: $transparent-dark-bg;
    background-color: $transparent-dark-bg;
  }
  .nav-link.active {
    border-top-color: $info;
    color: $info
  }
}
