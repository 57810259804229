.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 100%;
  background-color: #fff;
  font-family: $input-font-family;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  @include font-size($input-font-size);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: $input-border-width solid $input-border-color;
  z-index: $zindex-dropdown;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}