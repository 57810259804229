/*******************/
/*Chat widget*/
/*******************/
.chat-box {
  overflow: auto;
}



.chat-list-item {
  .chat-time {
    font-size: 10px;
    color: $gray-500;
    text-align: right;
    position: absolute;
    bottom: 5px;
    right: 15px;
    display: block;
  }
}

.chat-list {
  margin: 0px;
  padding: 0px;
  overflow: auto;
  position: relative;

  .left-part {
    width: 260px;
  }

  .chat-date {
    width: calc(100%);
    list-style: none;
    display: inline-block;
    text-align: center;
  }

  .chat-item {
    width: calc(90%);
    list-style: none;
    margin-top: 30px;
    display: flex;
    .chat-img {
      display: inline-block;
      width: 45px;
      vertical-align: top;

      img {
        width: 45px;
        border-radius: 100%;
      }
    }

    .chat-content {
      // width: calc(100% - 1px);
      width: calc(100%);
      display: inline-block;
      position: relative;

      .box {
        border-radius: 4px;
        display: inline-block;
        padding: 10px;
        margin-bottom: 3px;
        color: $gray-800;
        background: $light-info;
      }

      // .box3 {
      //   width: 300px;
      //   margin: 50px auto;
      //   border-radius: 15px;
      //   background: #00bfb6;
      //   color: #fff;
      //   padding: 20px;
      //   text-align: center;
      //   font-weight: 900;
      //   font-family: arial;
      //   position: relative;
      // }
      
          /* speech bubble 13 */
            
      .sb-odd {
        text-align: left;
      }

      .sb-odd:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 8px solid $light-danger;
        border-right: 8px solid transparent;
        border-top: 8px solid $light-danger;
        border-bottom: 8px solid transparent;
        right: -10px;
        top: 0px;
      }
      
      /* speech bubble 14 */
      
      .sb-even:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 8px solid transparent;
        border-right: 8px solid $light-info;
        border-top: 8px solid $light-info;
        border-bottom: 8px solid transparent;
        left: -10px;
        top: 0px;
      }
      
    }

    .chat-time {
      display: block;
      font-size: 10px;
      color: $gray-500;
      text-align: right;
      position: relative;
      right: -3px;
    }

    &.odd {
      display: inherit;
      float: right;
      .chat-content {
        text-align: right;
        // width: calc(100% - 0px);
        width: calc(100%);
      }

      .chat-time {
        text-align: right;
      }

      .box {
        clear: both;
        color: $gray-800;
        background: $light-inverse;
      }
    }

    &.even + .even {
      margin-top: 0px;
    }

    &.odd + .odd {
      margin-top: 0px;
    }

    &.reverse {
      text-align: right;

      .chat-time {
        text-align: left;
      }

      .chat-content {
        padding-left: 0px;
        padding-right: 15px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .chat-list {
    .left-part {
      width: 230px;
    }

    .chat-item {
      .chat-content {
        width: calc(100% - 7px);
      }

      .show-left-part {
        &.left-part-open {
          left: 269px;
          top: 73px;
        }
      }
    }
  }
}
